// Import Bootstrap Styles Here

@import "bootstrap/bootstrap.scss";
@import "bootstrap/bootstrap-grid.scss";
@import "bootstrap/bootstrap-reboot.scss";

// Import Font Awesome Here

@import "font-awesome/font-awesome.scss";

// Add Styles Here

.ag-header-cell-label .ag-header-cell-text {
    //text-overflow: clip;
    //overflow: visible;
    white-space: pre-line;
}

html {
    height: 100%;
}
body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
#root {
    position: relative;
    min-height: 100%;
    //padding-bottom: 60px;
}

$tab-border-color: #aaa;

.header-tab-bar {

    .header-tab-link {
        display: block;
        height: 32px;
        margin-bottom: 0;
        background-color: #eee;
        border-color: $tab-border-color;
        border-width: 1px;
        border-style: solid;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        padding-top: 5px;
        margin-right: 2px;
        padding-left: 5px;
        padding-right: 5px;
        color: #555;
    }
    .header-tab-link:hover {
        color: #333;
    }

    .header-tab-link.active {
        background-color: #fff;
        border-bottom-color: #fff;
        text-decoration: none;
        color: #333;
        cursor: default;
    }

}

// speech-bubble-ds (drop-shadow) modified from https://auralinna.blog/post/2017/how-to-make-a-css-speech-bubble-with-borders-and-drop-shadow/
.speech-bubble-ds {
    background: #efefef;
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 0 auto 30px;
    max-width: 400px;
    padding: 15px;
    position: relative;

    p {
        margin-bottom: 10px;

        :last-of-type {
            margin-bottom: 0;
        }
    }
}

.speech-bubble-ds__arrow {
    border-right: 21px solid transparent;
    border-top: 20px solid rgba(0, 0, 0, 0.2);
    bottom: -25px;
    position: absolute;
    left: 15px;

    &::before {
        border-right: 23px solid transparent;
        border-top: 23px solid #a7a7a7;
        bottom: 2px;
        content: "";
        position: absolute;
        left: 5px;
    }
    &::after {
        border-right: 21px solid transparent;
        border-top: 21px solid #efefef;
        bottom: 4px;
        content: "";
        position: absolute;
        left: 6px;
    }
}

.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
    cursor: not-allowed;
}
.Resizer.disabled:hover {
    border-color: transparent;
}

.no-bottom-margin {
    margin-bottom: 0;
}
.form-group label {
    font-weight: bold;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #ddd;
}
.dropdown-menu .dropdown-item:focus {
    background-color: #ddd;
}

button.swal-button.swal-download-btn {
    background-color: #007bff;
    opacity: .8;
    &:hover {
        background-color: #007bff;
        opacity: 1;
    }
}
button.swal-button.swal-replace-btn {
    background-color: #ffc107;
    color: #333;
    opacity: .8;
    &:hover {
        background-color: #ffc107;
        opacity: 1;
    }
}
button.swal-button.swal-delete-btn {
    background-color: #dc3545;
    opacity: .8;
    &:hover {
        background-color: #dc3545;
        opacity: 1;
    }
}
.faint-hover-background {
    a {
        outline-color: #fff;
    }
}
.faint-hover-background:hover {
    background-color: #efefef;
    a {
        outline-color: #efefef;
    }
}
.swal-modal {
    width: 550px !important;
}

.swal-lg {
    width: 850px !important;
}
